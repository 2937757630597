<template>
  <div class="pt-2 pb-2">
    <template v-if="data.type === 'youtube'">
      <div style="padding-bottom:56.25%;padding-top:30px;height:0;overflow:hidden;position:relative;">
        <iframe 
          @load="loaded"
          class="w-full"
          :src="data.url" 
          style="position:absolute;top:0;left:0;width:100%;height:100%;" 
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </template>
    <template v-else-if="data.type === 'vimeo'">
      <div style="padding:75% 0 0 0;position:relative;">
        <iframe 
          @load="loaded"
          :src="data.url" 
          style="position:absolute;top:0;left:0;width:100%;height:100%;" 
          frameborder="0" 
          allow="autoplay; fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </template>
  </div>
</template>


<script>
export default {
  name: 'VideoEmbed',
  props: ['data'],
  methods: {
    loaded() {
      this.$emit('init')
    }
  }
}
</script>